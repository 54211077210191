import { nextTick } from '../../../jelly-flux/src//utils'

import Head from '../models/Head'

/**
 * This function mutate the measures object !
 */
export default async ({ measures, measurer, styles, options }) => {
  const tickDate = new Date()
  const staffUnit = styles.staff.unit
  const topStaff = Head.lineIndexFromPitch('f', 5).absolute * staffUnit
  const staffHeight = (styles.staff.line.count - 1) * staffUnit + 1
  const midStaff = (staffHeight - styles.staff.line.height) / 2

  styles.staff.top = topStaff
  styles.staff.height = staffHeight
  styles.staff.middle = midStaff

  //
  // We eval the layout which measure the minimum required size elements and
  // eval cols use to layout.
  //
  let maxBeatWidth = 0
  measures.forEach((measure) => {
    measure.evalLayout(options, styles, measurer)
    if (measure.isStrict) {
      maxBeatWidth = Math.max(maxBeatWidth, measure.beatWidth)
    }
  })
  //
  // Once cols are defined, eval the layout again.
  //
  measures.forEach((measure) => {
    measure.updateLayout(options, maxBeatWidth)
  })
  await nextTick('STAFF MEASURER STEP 1', tickDate)
  //
  // Eval cross axis layout but not for containers (measures and track) because
  // they need grouped in rows first, which will be done with staff-composer
  // processor
  //
  let measureIndex = 0
  for await (const measure of measures) {
    measure.tracks.forEach((track) => {
      //
      // Blocs layout
      //
      track.blocs.forEach((bloc) => {
        bloc.layoutCrossAxis(styles)
      })
      if (!track.isNote) return
      //
      // Beam direction
      //
      track.beams.forEach((beam) => {
        beam.evalLayout(styles)
      })
      //
      // Beam offset (that will layout stem). Done after all beam layout.
      //
      track.beams.forEach((beam) => {
        beam.evalOffset(styles)
      })
      //
      // Stem
      //
      track.blocs.forEach((bloc) => {
        const beam = bloc.beams && bloc.beams[0]
        if (beam) {
          //
          // The bloc already has stem built by beam, we just apply the offset
          //
          bloc.stem.layoutCrossAxisBeam(beam, styles)
        } else {
          //
          // The bloc has no beam. The stem has to be created
          //
          bloc.addStem(styles)
          if (bloc.stem) {
            bloc.stem.layoutCrossAxisNote(styles)
          }
        }
        //
        // Flag
        //
        if (bloc.flag) {
          bloc.flag.layoutCrossAxis(styles)
        }
      })
      //
      // Beams layout
      //
      track.beams.forEach((beam) => {
        beam.layoutCrossAxis(styles)
      })
      //
      // Tuplets layout
      //
      if (track.tuplets) {
        track.tuplets.forEach((tuplet) => {
          tuplet.layoutCrossAxis(styles)
        })
      }
      //
      // Ties
      //
      if (track.ties) {
        track.ties.forEach((tie) => {
          tie.layoutCrossAxis(styles)
        })
      }
      //
      // Re eval bloc layout after (in case of need of dimensions of stem,
      // tuplet, beam or tie)
      //
      track.blocs.forEach((bloc) => {
        if (bloc.layoutCrossAxisAfter) {
          bloc.layoutCrossAxisAfter(styles)
        }
      })
    })
    await nextTick('STAFF MEASURER STEP 2-' + measureIndex, tickDate)
    measureIndex++
  }
}
