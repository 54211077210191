import { nextTick } from '../../../jelly-flux/src//utils'

import Row from '../models/Row'
import Head from '../models/Head'

const staffBound = {
  min: Head.lineIndexFromPitch('e', 4).absolute,
  max: Head.lineIndexFromPitch('f', 5).absolute
}
staffBound.height = staffBound.max - staffBound.min

/**
 * Warning: this function will mutate 'measures' and 'report'
 *
 *
 */

export default async ({
  measures,
  report,
  availableWidth,
  styles,
  options
}) => {
  const tickDate = new Date()
  const rows = []
  //
  // STEP 1 : Eval rows ========================================================
  // We can evaluate rows because we have an avalaible width
  //
  let row
  let breakline
  let rowMaxWidth = 0
  measures.forEach((measure) => {
    row = rows[rows.length - 1]
    const contentWidth = measure.measuredWidth
    //
    // Eval breakline and row creation
    //
    if (row) {
      if (options.isWrap) {
        breakline = contentWidth > row.availableWidth
      } else if (options.isFixed) {
        breakline = row.measures.length === options.maxMeasureByRow
      }
    }
    let firstOfRow = !row || breakline
    if (options.isBreakLine) {
      breakline = measure.breakline
    } else {
      breakline = false
    }
    //
    // Eval of the padding before and after.
    //
    measure.evalBeforeAndAfter(firstOfRow, styles)
    let measureWidth = contentWidth + measure.before + measure.after
    //
    // Check again the size with before and after
    //
    if (!firstOfRow && options.isWrap && measureWidth > row.availableWidth) {
      firstOfRow = true
      measure.evalBeforeAndAfter(firstOfRow, styles)
    }
    //
    // Create a row
    //
    if (firstOfRow) {
      if (row) {
        const lastMeasureOfRow = row.measures[row.measures.length - 1]
        lastMeasureOfRow.setAsLastOfRow()
      }
      let rowMaxWidth = availableWidth
      if (options.isBreakLine) {
        rowMaxWidth = Infinity
      }
      row = new Row(rowMaxWidth, row, rows.length)
      rows.push(row)
    }
    measure.linkToRow(row, firstOfRow)
    row.addMeasure(measure)
    if (measure.isLast) {
      measure.setAsLastOfRow()
    }
    rowMaxWidth = Math.max(row.innerWidth + row.fixedWidth, rowMaxWidth)
  })
  await nextTick('STAFF COMPOSER STEP 1', tickDate)
  //
  // STEP 2 : Eval overflows and Containers CrossAxis ==========================
  //
  let rowPosY = 0
  rows.forEach((row) => {
    //
    // Once the rows are created, we can eval the overflow of each row.
    //
    row.updateOverflow()
    //
    // Once the overflow is updated we can layout the cross axis
    //
    row.layoutCrossAxis(rowPosY, styles)
    rowPosY += row.height + styles.row.gutter
  })

  if (options.isFixed || options.isBreakLine || rows.length < 2) {
    availableWidth = rowMaxWidth //Math.min(availableWidth, rowMaxWidth)
  }
  rows.forEach((row) => {
    row.evalFlex(availableWidth)
    row.layoutMainAxis(styles)
  })
  await nextTick('STAFF COMPOSER STEP 2', tickDate)
  //
  // STEP 3 : Eval view frame ==================================================
  //
  const viewFrame = report.viewFrame
  report.viewFrame.height = 0
  rows.forEach((row) => {
    viewFrame.height += row.height
  })
  viewFrame.height += styles.row.gutter * (rows.length - 1)
  viewFrame.width = availableWidth
  return rows
}
