<template>
  <div class="staff-renderer">
    <canvas
      @select="onSelect($event)"
      class="pixi"
      width="100%"
      height="100%"
    />
    <div class="paper" ref="paper">
      <slot name="header" />
      <div v-if="isEmpty">
        <slot name="empty" />
      </div>
      <div v-show="!isEmpty" class="bound">
        <selection-bound ref="selectionBound" :items="selectionRows" />
        <div class="scroll-proxy" ref="scrollProxy" />
        <div class="score-proxy" ref="scoreProxy" :style="proxyStyle" />
      </div>
      <slot name="footer" />
    </div>
  </div>
</template>
<script>
import rendererMixin from '../../mixins/renderer-mixin'
import selectionMixin from '../../mixins/selection-mixin'

import svgs from '../../utils/svg-utils'

import staffBuilder from '../../processors/staff-builder'
import staffMeasurer from '../../processors/staff-measurer'
import staffComposer from '../../processors/staff-composer'
import SelectionBound from '../misc/SelectionBound.vue'

import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'

import PIXIRenderer from '../../renderers/pixi-renderer'
import { TextMetrics } from 'pixi.js'

export default {
  name: 'StaffRenderer',
  mixins: [rendererMixin, selectionMixin],
  components: {
    SelectionBound
  },
  props: {
    locked: Boolean,
    compact: Boolean
  },
  data: () => ({
    isEmpty: false,
    options: null,
    proxyStyle: {}
  }),
  created() {
    const loop = () => {
      if (!this._isLooping) return
      const proxy = this.$refs.scoreProxy
      const renderer = this._renderer
      if (proxy && renderer && renderer.sprites) {
        const proxyBound = proxy.getBoundingClientRect()
        const noChange =
          this.proxyBound &&
          this.proxyBound.x === proxyBound.x &&
          this.proxyBound.y === proxyBound.y &&
          this.proxyBound.width === proxyBound.width &&
          this.proxyBound.height === proxyBound.height
        const outOfBound =
          proxyBound.bottom < 0 ||
          proxyBound.right < 0 ||
          proxyBound.left > window.innerWidth ||
          proxyBound.top > window.innerHeight
        //
        // check changes
        //
        if (noChange || outOfBound) {
          window.requestAnimationFrame(loop)
          this.scrolling = false
          if (outOfBound) {
            renderer.resize(0, 0)
          }
          return
        }
        this.proxyBound = {
          x: proxyBound.x,
          y: proxyBound.y,
          width: proxyBound.width,
          height: proxyBound.height
        }
        this.scrolling = true
        //
        // Eval pixi bound
        //
        const pixiBound = {}
        pixiBound.left = Math.max(0, proxyBound.left)
        pixiBound.right = Math.min(window.innerWidth, proxyBound.right)
        pixiBound.top = Math.max(0, proxyBound.top)
        pixiBound.bottom = Math.min(window.innerHeight, proxyBound.bottom)
        pixiBound.width = pixiBound.right - pixiBound.left
        pixiBound.height = pixiBound.bottom - pixiBound.top
        // Sprites in pixi
        //
        const spriteX = Math.round(proxyBound.left - pixiBound.left)
        const spriteY = Math.round(proxyBound.top - pixiBound.top)
        renderer.layout(spriteX, spriteY)
        //
        // Canvas position
        //
        const thisBound = this.$el.getBoundingClientRect()
        const boundBound = this.$refs.paper.getBoundingClientRect()
        let canvasLeft = pixiBound.left - boundBound.left
        canvasLeft += this.paperMargin * (this.compact ? 0.5 : 1)
        canvasLeft = Math.round(canvasLeft)
        const canvasTop = Math.round(pixiBound.top - thisBound.top)
        const canvasStyle = renderer.canvas.style
        canvasStyle.transform = `translate(${canvasLeft}px,${canvasTop}px) scale(${
          renderer.isRetina ? 0.5 : 1
        })`
        //
        // Canvas size
        //
        const canvasWidth = Math.round(pixiBound.width)
        const canvasHeight = Math.round(pixiBound.height)
        renderer.resize(canvasWidth, canvasHeight)
      }
      window.requestAnimationFrame(loop)
    }
    this._isLooping = true
    loop()
  },
  destroyed() {
    this.clear(true)
    this._renderer = null
    this._canvas = null
    this._styles = null
    this._report = null
    this._isLooping = false
  },
  methods: {
    clear(forDestroy) {
      this._measure = null
      this._rows = null
      if (this._renderer) {
        this._renderer.clear()
      }
      if (forDestroy) {
        this._style = null
      }
    },

    async render({ fragments, settings, updates, availableWidth, report }) {
      const options = this.extractOptions(settings.user)
      const styles = this.extractStyles(settings.staff)
      this.paperMargin = settings.sheet.margin
      this._styles = styles
      this._report = report
      //
      // Check if it's empty
      //
      this.isEmpty = !report.hasMetric || !report.hasNote
      if (this.isEmpty) {
        this.clear()
        return
      }
      //
      // Create the renderer
      //
      let renderer
      if (!this._renderer) {
        const canvas = this.$el.querySelector('canvas')
        this._canvas = canvas
        renderer = new PIXIRenderer(
          canvas,
          styles,
          this.forceCanvas,
          !this.locked
        )
        this._renderer = renderer
      } else {
        renderer = this._renderer
      }
      //
      //
      // STAFF BUILDER
      //
      let measures = this._measures
      if (updates.has('fragments', 'renderer', 'transpose', 'renderOptions')) {
        const transpose = settings.user.transpose
        const renderOptions = settings.user.renderOptions
        const builder = await staffBuilder(
          fragments,
          report,
          transpose,
          renderOptions
        )
        updates.add('measures')
        measures = this._measures = builder.measures
        const eventMap = (this._blocMap = builder.eventMap)
        if (this.verbose) {
          console.log('STAFF BUILDER', { measures, eventMap })
        }
      }
      //
      // STAFF MEASURER
      //
      if (updates.has('measures', 'spacing')) {
        const measurer = (raw, type) => {
          let style = styles.label[type]
          return TextMetrics.measureText(raw, style)
        }
        await staffMeasurer({ measurer, measures, styles, options })
      }
      //
      // STAFF COMPOSER
      //
      let rows = this._rows
      if (updates.has('measures', 'layout', 'width', 'spacing')) {
        rows = this._rows = await staffComposer({
          measures,
          styles,
          report,
          availableWidth,
          options
        })
        updates.add('rows')
        if (this.verbose) {
          console.log('STAFF COMPOSER', { rows })
        }
      }
      // this.isEmpty = !rows.length
      //
      // PIXI RENDERER
      //
      if (updates.has('rows')) {
        const renderedRows = rows.map((row) => row.render())
        await renderer.render(report.viewFrame, renderedRows)
        if (this.verbose) {
          console.log('STAFF RENDERER', { renderedRows })
        }
      }

      this.proxyStyle = {
        width: `${report.viewFrame.width}px`,
        height: `${report.viewFrame.height}px`
      }
      await this.$nextTick()
    },

    extractOptions(settings) {
      const spacing = settings.spacing
      const layout = settings.layout
      const isStrict = spacing === 'strict'
      const isDense = spacing === 'dense'
      const isWrap = layout === 'wrap'
      const isBreakLine = layout === 'breakline'
      const isFixed = layout.endsWith('cols')
      let maxMeasureByRow
      if (isFixed) {
        maxMeasureByRow = Number(layout.split('-')[0])
      }
      this.options = {
        isWrap,
        isBreakLine,
        isDense,
        isStrict,
        isFixed,
        maxMeasureByRow
      }
      return this.options
    },

    extractStyles(settings) {
      //
      // TODO : Remove merging with svg
      //
      if (this._styles) return this._styles
      const result = cloneDeep(settings)
      svgs.forEach((svg) => {
        let path
        path = svg.id.replace('-', '.')
        set(result, path, { width: svg.sizes[0], height: svg.sizes[1] })
      })

      this._styles = result
      return result
    },

    onSelect(event) {
      if (this.locked) return
      if (this.scrolling) {
        event.preventDefault()
      } else {
        const bloc = event.detail.ref()
        this.select(bloc)
      }
    },

    updateSelection() {
      const selection = this.selection
      if (!selection || selection.isEmpty || !this._blocMap) {
        this.selectionRows = []
        return
      }
      //
      // Mixin override
      //
      const { start, end, beats } = selection
      const renderer = this._renderer
      const blocMap = this._blocMap
      const onBeat = beats && beats.length === 1
      const bounds = []
      const isMeasure = selection.isMeasure || selection.isLine
      let measureSprite
      let staffIds = new Set()
      for (let eventIndex = start; eventIndex <= end; eventIndex++) {
        const blocs = blocMap.get(eventIndex)
        if (!blocs) continue
        blocs.forEach((bloc) => {
          if (!measureSprite) {
            const measure = bloc.track.measure
            measureSprite = renderer.childById.get(`${measure.id}-background`)
          }
          if (bloc.isNote) {
            staffIds.add(`staff-${bloc.track.code}`)
            if (isMeasure) {
              bounds.push({
                left: 0,
                right: Number.MAX_VALUE
              })
            } else {
              //
              // If highlights is on beat, we don't want events that starts on
              // another beat that one highlighted
              //
              const blocEvent = bloc.events.get(bloc.eventIndex)
              if (onBeat && blocEvent.beatIndexInFragment != beats[0]) {
                return
              }
              //
              // Get bound of the renderer sprite
              //
              const sprite = renderer.childById.get(`${bloc.id}-bound`)
              if (sprite) {
                bounds.push({
                  left: sprite.x,
                  right: sprite.x + sprite.width
                })
              }
            }
          }
        })
      }

      if (bounds.length) {
        const allBound = { left: Number.MAX_VALUE, right: 0 }
        bounds.forEach((bound) => {
          allBound.left = Math.min(bound.left, allBound.left)
          allBound.right = Math.max(bound.right, allBound.right)
        })
        const rows = []
        staffIds.forEach((staffId) => {
          const staffSprite = renderer.childById.get(staffId)
          const left = Math.max(staffSprite.x, allBound.left)
          const width = Math.min(
            allBound.right - allBound.left,
            staffSprite.width
          )
          rows.push({
            style: {
              left: `${left}px`,
              width: `${width}px`,
              top: `${staffSprite.y}px`,
              height: `${staffSprite.height}px`
            }
          })
        })
        this.selectionRows = rows
        //
        // Set scroll proxy. We have to wait for the end of the animation of
        // the selection bound.
        //
        setTimeout(() => {
          //
          // Component may be disposed
          //
          if (!this.$refs.selectionBound) return
          const scrollProxy = this.$refs.selectionBound.getProxy()
          this.$emit('selection', { scrollTarget: scrollProxy })
        }, 100)
      }
    }
  }
}
</script>
<style scoped>
.bound {
  position: relative;
  width: min-content;
  transition-property: opacity;
  transition-duration: 0.5s;
  margin: auto;
}

.pixi {
  touch-action: auto !important;
  position: absolute;
  z-index: 1;
  transform-origin: top left;
}
</style>
