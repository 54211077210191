import Sprite from './data/Sprite'

export default class Row extends Sprite {
  constructor(availableWidth, last, index) {
    super()
    this.index = index
    this.availableWidth = availableWidth
    this.innerWidth = 0
    this.fixedWidth = 0
    this.height = 0
    this.width = 0
    this.measures = []
    this.isLast = true
    this.isFirst = true
    if (last) {
      last.next = this
      last.isLast = false
      this.isFirst = false
      this.prev = last
    }
  }

  addMeasure(measure) {
    const measureInnerWidth = measure.measuredWidth
    let measureWidth = measureInnerWidth + measure.before + measure.after
    this.measures.push(measure)
    this.set('availableWidth', this.availableWidth - measureWidth)
    this.set('innerWidth', this.innerWidth + measureInnerWidth, true)
    this.set('fixedWidth', this.fixedWidth + measureWidth - measureInnerWidth)
  }

  updateOverflow() {
    const overflows = []
    this.measures.forEach((measure) => {
      measure.tracks.forEach((track, trackIndex) => {
        if (!track.overflow) return
        const trackOverflow = track.overflow
        let overflow = overflows[trackIndex]
        if (!overflow) {
          overflow = { top: 0, bottom: 0 }
          overflows[trackIndex] = overflow
        }
        overflow.top = Math.max(trackOverflow.top, overflow.top)
        overflow.bottom = Math.max(trackOverflow.bottom, overflow.bottom)
      })
    })
    this.overflows = overflows
  }

  layoutCrossAxis(posY, styles) {
    let rowHeight
    const hidden = Array(this.measures[0].tracks.length).fill(true)
    const empty = hidden.slice()
    this.measures.forEach((measure) => {
      measure.tracks.forEach((track, trackIndex) => {
        track.blocs.forEach((bloc) => {
          if (hidden[trackIndex]) hidden[trackIndex] = bloc.isHidden || false
          if (empty[trackIndex]) empty[trackIndex] = bloc.isEmpty || false
        })
      })
    })
    this.hiddenTracks = hidden
    this.emptyTracks = empty
    this.measures.forEach((measure) => {
      measure.layoutCrossAxis(styles, hidden, empty)
      rowHeight = measure.height
    })
    this.set('y', posY)
    this.set('height', rowHeight)
  }

  /**
   * Eval row flex. this coeff will be used to fit each row on available width
   */
  evalFlex(availableWidth) {
    this.flex = (availableWidth - this.fixedWidth) / this.innerWidth
    if (this.isLast && this.flex > 1) {
      this.flex = 1
    }
  }

  layoutMainAxis(styles) {
    let measurePosX = 0
    this.measures.forEach((measure) => {
      measure.layoutMainAxis(measurePosX, styles)
      measurePosX += measure.width
    })
  }

  render() {
    return {
      y: this.y,
      transform: this.translate,
      height: this.height,
      measures: this.measures.map((measure) => measure.render())
    }
  }
}
