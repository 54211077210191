import { nextTick } from '../../../jelly-flux/src//utils'

// import Tune from '../models/Tune'
import Measure from '../models/Measure'
import EventMap from '../models/data/EventMap'

export default async (fragments, report, transpose, renderOptions) => {
  const tickDate = new Date()

  //
  // STEP 1 : Build measures ===================================================
  //
  // let currentTune = new Tune('c')
  let lastMeasure
  let fragmentIndex = 0
  const measures = []
  // eslint-disable-next-line
  for await (const fragment of fragments) {
    lastMeasure = new Measure(
      fragments,
      fragmentIndex,
      lastMeasure,
      transpose,
      renderOptions
    )
    if (lastMeasure.tracks.length) {
      measures.push(lastMeasure)
    }
    // currentTune = lastMeasure.tune
    await nextTick('STAFF BUILDER STEP 1-' + fragmentIndex, tickDate)
    fragmentIndex++
  }
  //
  // STEP 2 : Merge blocs ======================================================
  //
  const lastOfType = []
  measures.forEach((measure) => {
    measure.tracks.forEach((track) => {
      track.mergeBlocs(lastOfType)
    })
  })
  await nextTick('STAFF BUILDER STEP 2', tickDate)
  //
  // STEP 3 : Build blocs ======================================================
  //
  let currentHead = []
  measures.forEach((measure) => {
    measure.tracks.forEach((track, trackIndex) => {
      if (!track.isNote) return
      track.blocs.forEach((bloc) => {
        //
        // Bluild bloc
        //
        bloc.build(currentHead[trackIndex], report.ambitus)
        if (!bloc.head.isRest) {
          currentHead[trackIndex] = bloc.head
        }
      })
    })
  })
  await nextTick('STAFF BUILDER STEP 3', tickDate)
  //
  // STEP 4 : Build beams ======================================================
  //
  measures.forEach((measure) => {
    measure.tracks.forEach((track) => {
      track.buildBeams()
    })
  })
  await nextTick('STAFF BUILDER STEP 4', tickDate)
  //
  // STEP 5 : Build tuplets ====================================================
  //
  measures.forEach((measure) => {
    measure.tracks.forEach((track) => {
      track.buildTuplets()
    })
  })
  await nextTick('STAFF BUILDER STEP 5', tickDate)
  //
  // STEP 6 : Event Map ========================================================
  //

  const eventMap = new EventMap()
  // let lastBloc
  // let eventIndex = 0
  measures.forEach((measure) => {
    measure.tracks.forEach((track) => {
      track.blocs.forEach((bloc) => {
        bloc.events.forEach((event) => {
          //
          // Some event can be not linked to a bloc. See example in
          // chart-builder
          //
          // eventMap.fill(eventIndex, event.index, lastBloc)
          eventMap.set(event.index, bloc)
          // eventIndex = event.index
        })
        // lastBloc = bloc
      })
    })
  })

  report.blocMap = () => eventMap

  return {
    measures,
    eventMap
  }
}
