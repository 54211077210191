export default [
  //
  // Head
  //
  {
    id: 'head-whole',
    sizes: [13, 9],
    path:
      'M6.5,1c-8.58.13-8.58,6.87,0,7C15.08,7.87,15.08,1.13,6.5,1Zm.58,6.35c-2.95.05-5-5.57-1.16-5.7C8.87,1.6,10.93,7.21,7.08,7.35Z'
  },
  {
    id: 'head-half',
    sizes: [10, 9],
    path:
      'M6.18,1C3.48,1,.76,2.51.09,4.45c-1.13,5.36,8.72,4,9.76.13C10.52,2.64,8.87,1,6.18,1ZM8.35,3.72A12.26,12.26,0,0,1,2.88,6.88C1,7.32.44,6.61,1.6,5.3A12.24,12.24,0,0,1,7.06,2.15C8.93,1.71,9.5,2.42,8.35,3.72Z'
  },
  {
    id: 'head-quarter',
    sizes: [10, 9],
    viewBox: '0 0 10 9',
    path:
      'M9.64,4.5C8.62,6.43,5.71,8,3.15,8S-.67,6.43.36,4.5,4.29,1,6.85,1,10.67,2.57,9.64,4.5Z'
  },
  //
  // Rests
  //
  {
    id: 'rest-whole',
    sizes: [10, 33],
    path: 'M0,9H10v4H0Z'
  },
  { id: 'rest-half', sizes: [10, 33], path: 'M0,12H10v4H0Z' },
  {
    id: 'rest-quarter',
    sizes: [8, 33],
    path:
      'M3,4.21,7.56,9.49a.83.83,0,0,1,0,1.15C4,14.26,4.05,17.57,8,21.23c.21.19-.28.79-.51.66-4.37-2.73-5.72,1.81-2.82,4.52.17.21-.22.67-.42.58-5.13-2.42-6.42-10.15,1-7.22.08,0,.15-.07.1-.12L.89,14.12A.84.84,0,0,1,1,13c3.3-3,3.49-5.23.58-8.75C1.39,4,2.66,3.91,3,4.21Z'
  },
  {
    id: 'rest-8th',
    sizes: [9, 33],
    path:
      'M3.68,24.66l3-10.75c-1.83,1.12-3.65,1.7-5.45.39a2.38,2.38,0,0,1,1-4.2,2.24,2.24,0,0,1,2.08,3.38c1.12.73,2.7-.76,3.7-2.53h.57L4.82,24.66Z'
  },
  {
    id: 'rest-16th',
    sizes: [11, 33],
    path:
      'M7.08,20.22l1.68-6.16C7,15.23,5.07,15.8,3.3,14.43a2.37,2.37,0,0,1,1.06-4.2,2.29,2.29,0,0,1,2.21,3.39c1.23.23,2.75-1.12,3.61-2.53h.54l-6,21.41H3.61l3-10.74A6.85,6.85,0,0,1,3.2,22.87C1,23-.69,20.33.93,18.61c1.71-2,4.64.44,3.37,2.59.79.5,2.64-.53,2.78-1Z'
  },
  {
    id: 'rest-32th',
    sizes: [13, 33],
    path:
      'M7,20.08l1.74-6.15A6.52,6.52,0,0,1,5.31,15c-2.23.14-4-2.51-2.32-4.25,1.87-2,4.79.49,3.36,2.76.9.53,2.34-.79,2.84-1.42L10.86,6A8.11,8.11,0,0,1,7.45,7.07C5.3,7.2,3.6,4.69,5,2.92c1.75-2,4.72.22,3.62,2.54,1.09.31,2.71-1,3.64-2.47h.53L4.64,32.5H3.5l3-10.74a9.43,9.43,0,0,1-3.44,1.11C.9,23-.8,20.33.83,18.61c1.79-2,4.58.42,3.43,2.65.84.59,2.2-.65,2.72-1.18Z'
  },
  {
    id: 'rest-64th',
    sizes: [15, 33],
    path:
      'M3.47,40.56H4.59L15,3h-.54c-.93,1.43-2.55,2.78-3.63,2.47C11.94,3.14,9,.88,7.22,2.92,5.78,4.69,7.49,7.2,9.64,7.07A8.11,8.11,0,0,0,13.05,6l-1.68,6.16c-.49.63-1.93,2-2.83,1.42,1.43-2.27-1.49-4.8-3.36-2.76C3.52,12.52,5.26,15.17,7.5,15a6.55,6.55,0,0,0,3.41-1.1L9.17,20.08h0c-.52.53-1.89,1.77-2.72,1.18C7.59,19,4.81,16.62,3,18.61c-1.62,1.72.07,4.38,2.27,4.26a9.43,9.43,0,0,0,3.44-1.11l-.54,1.93.46-1.64L6.9,28.2h0C6.38,28.72,5,30,4.18,29.37c1.14-2.22-1.64-4.63-3.43-2.64C-.88,28.44.82,31.11,3,31a9.56,9.56,0,0,0,3.42-1.1l-3,10.67'
  },
  //
  // Flags
  //
  {
    id: 'flag-8th',
    sizes: [8, 24],
    path:
      'M.09,9.23V0h1c0,3.22.92,5,3.07,7.44C9,12.7,9.22,17.43,5.52,24H5.06c1.25-3.18,3-6.7,1.62-10.14C5.8,11.51,3.63,9,1.09,8.62v.61Z'
  },
  {
    id: 'flag-16th',
    sizes: [8, 26],
    path:
      'M7,14.36a8.16,8.16,0,0,1-.68,7.85H5.89c3.19-6.77-.22-8-4.89-11.41V12H0V0H1C1,5.05,9.36,5.78,7,14.36Zm-.36-.73c.28-3.52-1.16-6-5.65-8.58C1,9,4.81,10.9,6.65,13.63Z'
  },
  {
    id: 'flag-32th',
    sizes: [8, 26],
    path:
      'M0,0H1C1,5,9.19,5.66,7.06,14.28a7.62,7.62,0,0,1,.06,4.24c1.27,1.91.56,5.63-.74,7.57H6c3.1-6.26-.06-8.27-4.95-11.65V16H0ZM6.65,13.28C6.94,10,5.34,7.75,1,5,1,8,5,9.75,6.65,13.28Zm0,4.4c.24-3.19-1.46-5-5.65-8C1,12.72,4.78,14.19,6.65,17.68Z'
  },
  //
  // Alterations
  //
  {
    id: 'alt-sharp',
    sizes: [7, 24],
    path:
      'M6,9V6L5,6.33V1H4V6.67L2,7.33V1H1V7.67L0,8v3l1-.33v5L0,16v3l1-.33V24H2V18.33l2-.66V24H5V17.33L6,17V14l-1,.33v-5ZM4,14.67l-2,.66v-5l2-.66Z'
  },
  {
    id: 'alt-neutral',
    sizes: [7, 24],
    path: 'M2,7.6V1H1V18l4-1.6V24H6V6Zm3,5.8L2,14.6v-4L5,9.4Z'
  },
  {
    id: 'alt-flat',
    sizes: [7, 24],
    path:
      'M1,10V-2H0V17c3.06-1.52,6-3,6-6S3.75,6.59,1,10Zm0,5.5V11c.84-1.09,3-2.08,3,0C4,13.27,2.66,14.44,1,15.5Z'
  },
  //
  // Clefs
  //
  {
    id: 'clef-treble',
    sizes: [23, 64],
    path:
      'M.78,37.16c0-6.47,4.9-11,9.5-14.85.39-.32.36-.38.29-.77a34.62,34.62,0,0,1-.45-5.25c0-3.9,1.29-9.77,5.22-11.48.35,0,1.18.89,1.66,1.6a13.61,13.61,0,0,1,2.08,7.45c0,4.9-2.62,9-6.08,12.19-.19.2-.25.23-.19.61l.8,4.64c.1.58.1.58.93.58,9.21.14,10.33,12.47,2.33,15.32-.44.2-.48.16-.41.55a48.81,48.81,0,0,1,.93,6.85c0,5.44-4.16,6.4-6.31,6.4C6.25,61,5,57.86,5,55.78A3.43,3.43,0,0,1,8.43,52.1c3.79,0,4,5.58.92,6.27-.73.23-1,.32-1,.55,0,.41.83.92,2.56.92,1.89,0,5.09-.57,5.09-5.31a46.44,46.44,0,0,0-.87-6.43c-.06-.38-.12-.35-.48-.29C6.48,49.22.56,43.6.78,37.16Zm11.78,9.69a9.76,9.76,0,0,0,1.79-.16c.48-.09.51-.09.44-.45l-1.6-9.53c-.06-.35-.12-.39-.63-.26a3.76,3.76,0,0,0-.9,7.17c.63.18.62.73.09.77a3.27,3.27,0,0,1-.86-.19c-5.34-1.85-4.68-10.17,1-11.87.58-.2.58-.16.48-.77l-.67-4.1c-.06-.35-.13-.38-.45-.13a37.33,37.33,0,0,0-4.89,4.55C.5,38.66,3.3,46.8,12.56,46.85Zm-1-29c.06.55-.07,3.92.61,3.2,2.53-2.21,5.57-5.09,5.57-8.64,0-1.76-.87-2.62-1.92-2.62C13.87,9.77,11.56,12.84,11.56,17.83Zm3,18.81,1.57,9.12c.06.39.12.39.51.2a5.13,5.13,0,0,0-1.73-9.76C14.51,36.16,14.47,36.26,14.54,36.64Z'
  },
  //
  // Time signature
  //
  {
    id: 'time-signature-1',
    sizes: [14, 17],
    path:
      'M1.5,8.17,4.86,1.65c.16-.33.36-.66.8-.54a17.76,17.76,0,0,0,3.64,0c.49,0,.7.21.7.69V14a1.24,1.24,0,0,0,1.12,1.05c.72,0,.69.92,0,.92H3.64c-.46,0-.77-.15-.77-.48s.35-.44.59-.44C4.2,15.08,5,14.63,5,14V6.26c0-.43-.26-.59-.56-.12S2.55,8.77,2.55,8.77C2.25,9.35.93,8.87,1.5,8.17Z'
  },
  {
    id: 'time-signature-2',
    sizes: [14, 17],
    path:
      'M1.52,2.92C4.35-.26,13.42.54,13.35,5.52c0,2.42-2,3.27-4.16,3.82-.69.18-3.35,1.06-4,2.11,1.34-.5,3.7.2,4.54.52,1.21.52,2.17.75,2.69-.84,0,0,0-.06,0-.09.09-.38.83-.46.79.11-.5,4.53-2.91,4.65-4.26,4.65-2.79,0-3.26-1.58-4.51-1.58C2.58,14.22,2.21,16,1.52,16s-.78-.7-.78-1.14c1-4.55,8.11-5.23,8.07-10.24,0-.85,0-2.81-2.81-2.81-1.85,0-2.41.7-2.48,1.11,0,.59,2.16.67,2.16,2.43C5.46,10.58-1.9,6.83,1.52,2.92Z'
  },
  {
    id: 'time-signature-3',
    sizes: [14, 17],
    path:
      'M6.38,15.77c-3.45-.06-5.22-1.1-5.28-3.08a2.42,2.42,0,0,1,2.45-2.43,2,2,0,0,1,2.05,2c-.11,1-1.78,2.62.24,2.79,1.54.12,2.9-.38,3-3.68,0,0-.05-2.92-4.17-2.92-.69.08-.87-.35,0-.46,4.55.21,5.9-5.73.91-6.07C4.53,1.69,3.1,3.31,4.86,3.55s.78,3.25-1,3C1.55,6.54.54,4.2,1.91,2.87,3.69.49,11.81.53,12.22,4.73A2.76,2.76,0,0,1,9.76,7.44a.52.52,0,1,0-.06,1l1,.26a3.33,3.33,0,0,1,2.18,2.89C12.89,14.68,9.38,15.77,6.38,15.77Z'
  },
  {
    id: 'time-signature-4',
    sizes: [14, 17],
    path:
      'M5.71,15.8c-.43.09-.57-.82.11-.76,1.79,0,1.33-1.82,1.38-3a.2.2,0,0,0-.2-.2H.65C.32,11.69.08,11.4.31,11,3.08,7.55,4.65,4.15,4.62,1.62c0-.24.18-.36.53-.36,1.41.06,3,.06,4.44,0,.17,0,.32.11.26.28C9.79,2,4.32,8.66,2.09,11.07a.21.21,0,0,0,.19.13H7a.2.2,0,0,0,.2-.2V8.23C7.14,8,6.77,7.89,7,7.68s3.18-3.89,3.47-3.63a.26.26,0,0,1,.3.31V11a.2.2,0,0,0,.2.2,11,11,0,0,0,2.53-.12.74.74,0,0,1-.33.72H11a.2.2,0,0,0-.2.2c0,1.08-.31,3,1.11,3a.4.4,0,1,1,.06.79Z'
  },
  {
    id: 'time-signature-5',
    sizes: [14, 17],
    path:
      'M6.79,15.77c-2.3,0-3.89-.46-4.71-1.37C.72,12.93,1.22,9.52,3.72,9.7A2.18,2.18,0,0,1,6,11.84a2.13,2.13,0,0,1-1.12,1.83c-.67.27-.57.94,0,1.19,1.76.3,3.58.05,3.78-3.81,0-4.45-4.07-5.18-6.15-2.71-.18.34-.77.23-.71-.09l.35-6.67c0-.08.05-.32.58-.32a37.08,37.08,0,0,0,8.3,0c.13,0,.25,0,.25.43C11,5.1,6.2,4.46,3.77,4.13a.68.68,0,0,0-.85.57c0,.26-.16,2-.16,2-.07.34.17.69.56.5,3.38-1.7,9-1.16,9.32,3.91C12.65,13.89,10.26,15.8,6.79,15.77Z'
  },
  {
    id: 'time-signature-6',
    sizes: [14, 17],
    path:
      'M1.94,4.22A6.69,6.69,0,0,1,7.8,1.06c1.73,0,4.22.5,4.54,2.41C12.86,6.79,6.58,7,8,3.35c.54-.7,1.63-1.5-.25-1.58-.68,0-2.56.15-2.66,5.67,0,.57.12.85.48.66,2.82-2.47,7.75-.17,7.74,3.28,0,3-3,4.78-6,4.59C1.16,16.11-.82,8.58,1.94,4.22ZM5.3,11.83c.07,4.42,3.66,4.39,3.75,0C9,7.43,5.37,7.4,5.3,11.83Z'
  },
  {
    id: 'time-signature-7',
    sizes: [14, 17],
    path:
      'M.57,2.1c0-.47.54-.43.71-.15s.57.72.83.72C3.38,2.24,3.28,1,5.77,1.06c2.66,0,4.16,2.54,5,2.54,1.21-.15,1-2.42,2.15-2.4.55,0,.55.39.55,1.2.06,3.18-3.82,7.4-3.82,12.47,0,.86-.19,1.1-.51,1.1s-1.83-.27-2.63-.27S5,16,4.39,16c-1.78-.19.47-4.1,1.6-5.14C7.91,8.71,10.38,7,10.38,6.2c-.05-.35-.27-.31-.51-.15C6.35,9,3.57.47,1.4,6.91c-.22.82-.78.74-.83.12Z'
  },
  {
    id: 'time-signature-8',
    sizes: [14, 17],
    path:
      'M3.73,8.83c-4.81-2.47-3-8,3.33-7.77.84,0,5.38.43,5.38,3.37a3.41,3.41,0,0,1-1.86,3.05c1.45.69,2.54,1.64,2.54,3.37,0,3.88-3.31,5.12-6.4,5.12C1.12,16.3-1.6,11.13,3.73,8.83ZM6.6,15c2.07,0,3-1,3-2.13,0-1.9-2.29-2.5-4.55-3.43C1,11,2.42,15.11,6.6,15ZM3.88,3.7C3.88,5.52,6.51,6,9,6.82a2.66,2.66,0,0,0,1.63-2.45c0-2-2.62-2.48-3.61-2.48C5.64,1.89,3.88,2.5,3.88,3.7Z'
  },
  {
    id: 'time-signature-9',
    sizes: [14, 17],
    path:
      'M.7,5.65c0-3,3-4.78,6-4.59C11,.86,13.36,4.87,13.3,8.45c0,3.79-2.7,7.6-7.1,7.52C4.47,16,2,15.46,1.66,13.56s2.62-3.49,4.09-2A1.77,1.77,0,0,1,6,13.67c-.54.71-1.63,1.51.25,1.59.68,0,2.56-.15,2.66-5.67,0-.57-.12-.86-.48-.66C5.62,11.4.69,9.1.7,5.65ZM6.84,8.54c2.44-.08,2.44-6.6,0-6.68C4.36,2,4.36,8.45,6.84,8.54Z'
  },
  {
    id: 'time-signature-12',
    sizes: [22, 17],
    path:
      'M21,11s0,.06,0,.09c-.37.92-.79,1.49-1.73,1.16a7.65,7.65,0,0,1-.93-.32c-.83-.32-3.16-1-4.48-.52.68-1,3.3-1.93,4-2.11,2.17-.55,4.11-1.4,4.11-3.82.07-5-8.89-5.78-11.68-2.6A3.1,3.1,0,0,0,9.6,6.31a2.93,2.93,0,0,0,2.54,1.6c1.88,0,2.16-1.83,2.16-2.56,0-1.76-2.13-1.84-2.13-2.43.06-.41.62-1.11,2.44-1.11,2.75,0,2.78,2,2.78,2.81,0,2.65-2.31,4.67-4.54,6A7.07,7.07,0,0,0,9.6,14.86c0,.1-.17.2-.15.31-.08-.05,0-.09-.11-.09C8.89,15.08,9,14.54,9,14V1.77c0-.48-.21-.66-.7-.69a17.76,17.76,0,0,1-3.64,0c-.44-.12-.64.21-.8.54L.5,8.17A.48.48,0,0,0,.75,9L1.06,9a.56.56,0,0,0,.49-.26S3.06,6.71,3.44,6.14,4,5.83,4,6.26V14c0,.68-.8,1.13-1.54,1.13-.24,0-.59.09-.59.44s.31.48.77.48H9.31c.28,0,.25-.16.3-.35a.65.65,0,0,0,.58.35c.68,0,1.05-1.78,2.88-1.78,1.23,0,1.7,1.58,4.45,1.58,1.33,0,3.71-.12,4.2-4.65C21.77,10.58,21,10.66,21,11Z'
  },
  // BARS
  {
    id: 'bar-single',
    sizes: [1, 33],
    path: 'M 0 0 L 1 0 L 1 33 L 0 33 L 0 0'
  },
  {
    id: 'bar-end',
    sizes: [7, 33],
    path: 'M 0 0 L 1 0 L 1 33 L 0 33 L 0 0 M 3 0 L 7 0 L 7 33 L 3 33 L 3 0'
  }
]
