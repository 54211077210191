// import * as PIXI from 'pixi.js'
import * as PIXI from 'pixi.js-legacy'

const ASSETS_URL = '/assets/spritesheet.json'
const ASSETS_URL_RETINA = '/assets/spritesheet-retina.json'
const pendingSetup = []

// PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST
// PIXI.settings.RESOLUTION = 0.5
PIXI.utils.skipHello()
PIXI.Application.prototype.render = null

export default class PIXISTAFFRenderer {
  constructor(canvas, styles, forceCanvas, interactive) {
    this.canvas = canvas
    this.styles = styles
    this.forceCanvas = forceCanvas
    this.isRendered = false
    this.isInteractive = interactive
    this.isRetina = window.devicePixelRatio > 1.5
    const shared = PIXI.Loader.shared
    const resources = shared.resources
    const assetUrl = this.isRetina ? ASSETS_URL_RETINA : ASSETS_URL
    if (!resources[assetUrl]) {
      pendingSetup.push(this.setup.bind(this))
      shared.add(assetUrl).load(() => {
        pendingSetup.forEach((pending) => pending())
      })
    } else if (resources[assetUrl].data) {
      this.setup()
    } else {
      pendingSetup.push(this.setup.bind(this))
    }
  }

  setup() {
    const resources = PIXI.Loader.shared.resources
    const assetUrl = this.isRetina ? ASSETS_URL_RETINA : ASSETS_URL
    this.spriteSheet = resources[assetUrl].spritesheet
    // CREATE APP

    const app = (this.pixiapp = new PIXI.Application({
      antialias: true,
      view: this.canvas,
      backgroundColor: 0xffffff,
      forceCanvas: this.forceCanvas,
      resolution: this.isRetina ? 2 : 1
    }))

    app.renderer.plugins.interaction.autoPreventDefault = false

    app.ticker.add(() => {
      if (this.needToBeRefreshed) {
        app.renderer.render(app.stage)
        this.needToBeRefreshed = false
      }
    })

    this.sprites = new PIXI.Container({ alpha: true })
    this.pixiapp.stage.addChild(this.sprites)
    this.childById = new Map()
    this.displayItems = []

    if (this.pendingRender) {
      this.render(this.pendingRender.viewport, this.pendingRender.rows)
    }

    if (this.onReady) this.onReady()
  }

  layout(spriteX, spriteY) {
    if (this.sprites.x != spriteX || this.sprites.y != spriteY) {
      this.sprites.x = spriteX
      this.sprites.y = spriteY
      this.pixiapp.renderer.render(this.pixiapp.stage)
    }
  }

  resize(canvasWidth, canvasHeight) {
    // canvasWidth /= window.devicePixelRatio
    // canvasHeight /= window.devicePixelRatio
    this.pixiapp.renderer.resize(canvasWidth + 1, canvasHeight + 1)
    this.pixiapp.renderer.render(this.pixiapp.stage)
  }

  addChild(id, item) {
    if (!id) {
      throw new Error('WTF : PIXI child id cannot be undefined')
    }
    this.sprites.addChild(item)
    this.childById.set(id, item)
    this.displayItems.push({ id, item })
  }

  clear() {
    this.displayItems.forEach(({ item }) => {
      this.sprites.removeChild(item)
      item.destroy()
    })
    this.childById.clear()
    this.displayItems.length = 0
  }

  getSprite(id, name) {
    const sprite = new PIXI.Sprite(this.spriteSheet.textures[name + '.png'])
    this.addChild(id, sprite)
    return sprite
  }

  getLabel(id, text, style) {
    const label = new PIXI.Text(text, this.styles.label[style])
    label.styleName = style
    this.addChild(id, label)
    return label
  }

  getRect(id, width, height, color) {
    const graphics = new PIXI.Graphics()
    graphics.beginFill(color || 0x999999)
    graphics.drawRoundedRect(0, 0, width, height, 2)
    graphics.endFill()
    this.addChild(id, graphics)
    return graphics
  }

  async render(viewport, rows) {
    return new Promise((resolve) => {
      if (!this.pixiapp) {
        this.onReady = () => {
          this.secureRender({ viewport, rows })
          resolve()
        }
      } else {
        this.secureRender({ viewport, rows })
        resolve()
      }
    })
  }

  secureRender({ rows }) {
    this.clear()

    this.pixiapp.renderer.backgroundColor = this.styles.background.color

    rows.forEach((row) => {
      const rowY = row.y
      row.measures.forEach((measure) => {
        const measureX = measure.x
        //
        // Measure background
        //
        const measureSrite = this.getRect(
          measure.background.id,
          measure.background.width,
          measure.background.height
        )
        measureSrite.y = rowY
        measureSrite.x = measureX
        measureSrite.alpha = 0

        measure.tracks.forEach((track) => {
          const trackY = rowY + track.y
          //
          // STAFF
          //
          if (track.staff) {
            const staff = track.staff
            const staffSprite = this.getSprite(staff.id, 'staff')
            staffSprite.width = staff.width
            staffSprite.y = trackY
            staffSprite.x = measureX
            this.addListener(staffSprite, track.blocs[0])
          }
          //
          // BEFORE
          //
          if (track.before) {
            //
            // BACKGROUND (BEFORE)
            //
            if (track.background.before) {
              const before = track.background.before
              const beforeGraphic = this.getRect(
                before.id,
                before.width,
                before.height,
                before.color
              )
              beforeGraphic.x = measureX
              beforeGraphic.y = trackY + before.y
            }
            //
            // CLEF
            //
            if (track.clef) {
              const clef = track.clef
              const clefSprite = this.getSprite(clef.id, clef.png)
              clefSprite.x = measureX + clef.x
              clefSprite.y = trackY + clef.y
            }
            //
            // KEY SIGNATURE
            //
            if (track.keySignature) {
              const keys = track.keySignature.keys
              keys.forEach((key) => {
                const keySprite = this.getSprite(key.id, key.png)
                keySprite.x = measureX + key.x
                keySprite.y = trackY + key.y
              })
            }
            //
            // TIME SIGNATURE
            //
            if (track.timeSignature) {
              const time = track.timeSignature
              const upSprite = this.getSprite(time.up.id, time.up.png)
              upSprite.x = measureX + time.up.x
              upSprite.y = trackY
              const downSprite = this.getSprite(time.down.id, time.down.png)
              downSprite.x = measureX + time.down.x
              downSprite.y = trackY + time.down.y
            }
          }
          //
          // BLOCS
          //
          track.blocs.forEach((bloc) => {
            const blocX = measureX + bloc.x
            const blocY = bloc.y + trackY
            //
            // BACKGROUND (BLOC)
            //
            if (bloc.background) {
              const backgroundId = `${bloc.id}-background`
              const backgroundGraphic = this.getRect(
                backgroundId,
                bloc.background.width,
                bloc.background.height,
                bloc.background.color
              )
              backgroundGraphic.x = blocX + bloc.background.x || 0
              backgroundGraphic.y = blocY + bloc.background.y || 0
            }
            //
            // BOUND (BLOC)
            //
            let boundGraphic
            if (bloc.bound) {
              const boundId = `${bloc.id}-bound`
              boundGraphic = this.getRect(
                boundId,
                bloc.bound.width,
                bloc.bound.height,
                this.styles.bound.color
              )
              boundGraphic.x = blocX + bloc.bound.x || 0
              boundGraphic.y = blocY + bloc.bound.y || 0
              boundGraphic.alpha = this.styles.bound.alpha.default
              this.addListener(boundGraphic, bloc, true)
            }
            //
            // CONTENT (BLOC)
            //
            if (bloc.isNote) {
              //
              // Extra
              //
              if (bloc.extra) {
                const extra = bloc.extra
                extra.lines.forEach((line) => {
                  const extraSprite = this.getSprite(line.id, extra.png)
                  extraSprite.x = blocX + extra.x
                  extraSprite.y = blocY + line.y
                  extraSprite.width = extra.width
                })
              }
              //
              // Accidental
              //
              if (bloc.accidental) {
                const alt = bloc.accidental
                const altSprite = this.getSprite(alt.id, alt.png)
                altSprite.x = blocX + alt.x
                altSprite.y = blocY + alt.y
              }
              //
              // Stem
              //
              if (bloc.stem) {
                const stem = bloc.stem
                const stemSprite = this.getSprite(stem.id, stem.png)
                stemSprite.x = blocX + stem.x
                stemSprite.y = blocY + stem.y
                stemSprite.height = stem.height
              }
              //
              // Flag
              //
              if (bloc.flag) {
                const flag = bloc.flag
                const flagSprite = this.getSprite(flag.id, flag.png)
                flagSprite.x = blocX + flag.x
                flagSprite.y = blocY + flag.y
                flagSprite.scale.y = flag.scale
              }
              //
              // head
              //
              const head = bloc.head
              const headSprite = this.getSprite(head.id, head.png)
              headSprite.x = blocX + head.x
              headSprite.y = blocY + head.y
              //
              // Dots
              //
              if (bloc.dot) {
                const dot = bloc.dot
                dot.bullets.forEach((bullet) => {
                  const dotSprite = this.getSprite(bullet.id, dot.png)
                  dotSprite.x = blocX + bullet.x
                  dotSprite.y = blocY + bullet.y
                })
              }
            } else {
              //
              // Label
              //
              if (bloc.label) {
                const label = bloc.label
                const labelSprite = this.getLabel(
                  label.id,
                  label.value,
                  label.style
                )
                labelSprite.x = blocX + label.x
                labelSprite.y = blocY + label.y
                if (boundGraphic) {
                  boundGraphic.label = labelSprite
                }
              }
              //
              // Anchor
              //
              if (bloc.anchor) {
                const anchor = bloc.anchor
                const anchorSprite = this.getSprite(anchor.id, anchor.png)
                anchorSprite.x = blocX + anchor.x
                anchorSprite.y = blocY + anchor.y
                anchorSprite.width = anchor.width
              }
            }
          })
          //
          // BACKGROUND (AFTER)
          //
          if (track.background.after) {
            const after = track.background.after
            const afterGraphic = this.getRect(
              after.id,
              after.width,
              after.height,
              after.color
            )
            afterGraphic.x = measureX + after.x
            afterGraphic.y = trackY + after.y
          }
          //
          // BAR (AFTER)
          //
          if (track.bar) {
            const bar = track.bar
            const barSprite = this.getSprite(bar.id, bar.png)
            barSprite.x = measureX + bar.x
            barSprite.y = trackY
          }
          //
          // BEAMS
          //
          if (track.beams) {
            const beams = track.beams
            beams.forEach((beam) => {
              const beamSprite = this.getSprite(beam.id, beam.png)
              beamSprite.x = measureX + beam.x
              beamSprite.y = trackY + beam.y - 1
              beamSprite.width = beam.width
              beamSprite.skew.y = beam.skew
              beamSprite.rotation = beam.rotation
            })
          }
          //
          // TUPLETS
          //
          if (track.tuplets) {
            const tuplets = track.tuplets
            tuplets.forEach((tuplet) => {
              const tupletX = measureX + tuplet.x
              const tupletY = trackY + tuplet.y
              //
              // TUPLET BARS
              //
              if (tuplet.bars) {
                const left = tuplet.left
                const leftSprite = this.getSprite(left.id, left.png)
                leftSprite.x = tupletX
                leftSprite.y = tupletY + left.y - 1
                leftSprite.width = tuplet.bars.width

                const right = tuplet.right
                const barRight = this.getSprite(right.id, right.png)
                barRight.x = tupletX + right.x
                barRight.y = tupletY + right.y - 1
                barRight.width = tuplet.bars.width

                const leftEnd = tuplet.leftEnd
                const leftEndSprite = this.getSprite(leftEnd.id, leftEnd.png)
                leftEndSprite.x = tupletX
                leftEndSprite.y = tupletY + leftEnd.y

                const rightEnd = tuplet.rightEnd
                const rightEndSprite = this.getSprite(rightEnd.id, rightEnd.png)
                rightEndSprite.x = tupletX + rightEnd.x
                rightEndSprite.y = tupletY + rightEnd.y
              }
              //
              // TUPLET VALUE
              //
              const text = tuplet.text
              const textSprite = this.getSprite(text.id, text.png)
              textSprite.x = tupletX + text.x
              textSprite.y = tupletY + text.y
            })
          }
          //
          // Ties
          //
          if (track.ties) {
            const ties = track.ties
            ties.forEach((tie) => {
              const tieSprite = this.getSprite(tie.id, tie.png)
              tieSprite.x = measureX + tie.x
              tieSprite.y = trackY + tie.y
              tieSprite.width = tie.width
              tieSprite.scale.y = tie.scale
            })
          }
        })
      })
    })

    // this.pixiapp.renderer.render(this.pixiapp.stage)
    this.needToBeRefreshed = true
    this.isRendered = true
  }

  //
  // LISTENERS =================================================================
  //

  addListener(g, bloc, buttonMode) {
    if (!this.isInteractive) return
    g.interactive = true
    g.buttonMode = buttonMode
    g.on('pointerdown', () => this.onButtonDown(g))
      .on('pointerup', () => this.onButtonUp(g, bloc))
      .on('pointerupoutside', () => this.onButtonUpOut(g))
      .on('pointerover', () => this.onButtonOver(g))
      .on('pointerout', () => this.onButtonOut(g))
  }

  onButtonDown(g) {
    g.isdown = true
  }
  onButtonOver(g) {
    g.isOver = true
    if (g.isdown) return
  }
  onButtonUp(g, bloc) {
    g.isdown = false
    const detail = bloc
    this.canvas.dispatchEvent(new CustomEvent('select', { detail }))
  }
  onButtonUpOut(g) {
    g.isdown = false
  }
  onButtonOut(g) {
    g.isOver = false
    if (g.isdown) return
  }
}
